<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            label="대상년도"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" :isFirstValue="false" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-checkbox
            :editable="editable"
            trueLabel="O"
            falseLabel="전체"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="검진종류"
            name="checkupFlags"
            v-model="searchParam.checkupFlags"
          />
        </div>
        <!-- <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            trueLabel="O"
            falseLabel="전체"
            label="일반검진"
            name="normalFlag"
            v-model="searchParam.normalFlag"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            trueLabel="O"
            falseLabel="전체"
            label="초음파검진"
            name="ultrasoundFlag"
            v-model="searchParam.ultrasoundFlag"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            trueLabel="O"
            falseLabel="전체"
            label="배치전검진"
            name="batchFlag"
            v-model="searchParam.batchFlag"
          />
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            :comboItems="regulFlagItems"
            name="regulFlag"
            label="정규직/임시직"
            v-model="searchParam.regulFlag"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="HRM_POS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="spotCd"
            label="직위"
            v-model="searchParam.spotCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="건강검진 실시여부 목록"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="userId"
      :columnSetting="false"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveRemark"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '../../js/transactionConfig';
export default {
  name: 'check-up-implementation',
  data() {
    return {
      flagItems: [
        { code: 'synthesisFlag', codeName: '종합검진' },
        { code: 'normalFlag', codeName: '일반검진' },
        { code: 'ultrasoundFlag', codeName: '초음파검진' },
        { code: 'batchFlag', codeName: '배치검진' },
      ],
      regulFlagItems: [
        { code: 'Y', codeName: '정규직' },
        { code: 'N', codeName: '임시직' },
      ],
      grid: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
          { index: 1, colName: 'deptCd' },
          { index: 2, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 80px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'upDeptName',
            field: 'upDeptName',
            style: 'width: 100px',
            label: '상위소속',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width: 80px',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '성명',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'spotName',
            field: 'spotName',
            style: 'width: 80px',
            label: '직위',
            align: 'center',
            sortable: true,
          },
          {
            name: 'jobName',
            field: 'jobName',
            style: 'width: 80px',
            label: '직책',
            align: 'center',
            sortable: true,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            style: 'width: 100px',
            label: '생년월일',
            align: 'center',
            sortable: true,
          },
          {
            name: 'age',
            field: 'age',
            style: 'width: 60px',
            label: '연령',
            align: 'center',
            sortable: true,
          },
          {
            name: 'enterDate',
            field: 'enterDate',
            label: '입사일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'longevityCount',
            field: 'longevityCount',
            label: '근속년수',
            align: 'left',
            style: 'width: 90px',
            sortable: false,
          },
          {
            label: '건강검진 실시여부',
            align: 'center',
            child: [
              {
                name: 'synthesisFlag',
                field: 'synthesisFlag',
                label: '종합검진',
                style: 'width: 80px',
                align: 'center',
                helpcomment: 
                '1. 인사기록 상 연령 : 41, 43, 45, 47, 49, 51, 53, 55 + 56세 이상<br> ' +
                '2. 근속년수 : 4년 이상<br> ' +
                '3. 직위 : 임시직 및 계약직 제외 ',
                sortable: true,
              },
              {
                name: 'normalFlag',
                field: 'normalFlag',
                label: '일반검진',
                style: 'width: 80px',
                align: 'center',
                helpcomment: 
                '1. 근무지: 서울사무소는 1회/2년 실시(홀수년생은 홀수년도, 짝수년생은 짝수년도)<br> ' +
                '2. 단 당해입사자는 상관없이 내년에 진행하며, 그 이후로는 주기에 따라 실시<br> ' + 
                '3. 서울사무소 外 근무지 대상자들은 전부 1회/1년 실시<br> ' + 
                '4. 종합검진 대상자는 제외<br> ' + 
                '5. 직위 : 임시직 및 계약직 제외',
                sortable: true,
              },
              {
                name: 'ultrasoundFlag',
                field: 'ultrasoundFlag',
                label: '초음파검진',
                style: 'width:80px',
                align: 'center',
                helpcomment: 
                '1. 연 1회 실시 <br>' +
                '2. 직위 : 임시직 및 계약직 제외 <br> ' + 
                '3. 단, 계약직 중 무기계약직은 포함 <br> ' +
                '4. 종합검진 대상자는 제외',
                sortable: true,
              },
            ]
          },
          {
            label: '배치전검진 실시여부',
            align: 'center',
            child: [
              {
                name: 'beforeBatchFlag',
                field: 'beforeBatchFlag',
                label: '입사일 기준',
                style: 'width:80px',
                helpcomment: 
                '1. 입사일 다음날 ',
                align: 'center',
                sortable: true,
              },
              {
                name: 'batchMoveFlag',
                field: 'batchMoveFlag',
                label: '반이동 기준',
                style: 'width:80px',
                type: 'check',
                true: 'Y',
                false: 'N',
                value: 'N',
                helpcomment: 
                '1. 새로운 물질을 다루거나 다른 유기용제를 취급하는 반으로 이동할 경우 수기로 입력하여 수정',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width: 250px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        userId: '',
        year: '',
        checkupFlags: [],
        // synthesisFlag: 'N',
        // normalFlag: 'N',
        // ultrasoundFlag: 'N',
        // batchFlag: 'N',
        spotCd: null,
        regulFlag: null,
      },
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.implementation.list.url;
      this.saveUrl = transactionConfig.hea.checkup.implementation.save.url;
      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '대상년도를 선택하세요.',
          type: 'error', // success / info / warning / error
        });
        return;
      }
      
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveRemark() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
